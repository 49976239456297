.containers.contact_container {
  width: 70%;
  display: grid;
  grid-template-columns: 30% 65%;

  gap: 12%;
  height: 100%;
  margin-top: 0;
}

.contact_header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
}

.contact_header h2 {
  font-size: 2rem;
  color: var(--color-white);
  font-family: "Noto Serif JP", sans-serif;
}

.contact_header p {
  font-size: 1rem;
  color: var(--color-b-primary);
}

.contact_options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact_option {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.2rem;
  align-items: center;
  border-radius: 1.2rem;
  text-align: center;
  border: 2px solid #ebebeb;
  transition: var(--transition);
  color: var(--color-b-primary);
  margin: 2rem;
}

.contact_option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
  color: var(--color-b-primary);
}

.contact_option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
}

/* =====Form Styling===== */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0;
  padding: 0;
}

.contact__form-label {
  color: var(--color-b-primary);
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 0.6rem;
  display: block;
  text-align: left;
}

.contact__form-field {
  border-bottom: 1px solid #fff;
  padding-top: 3rem;
}

.contact__form-input {
  color: #fff;
  padding: 2rem;
  width: 100%;
  font-size: 0.9rem;
  letter-spacing: 0;
  border-radius: 5px;
  font-weight: 500;
  outline: none;
  background: none;
}

input {
  height: 10px;
}

.popup {
  text-align: center;
  font-weight: 600;
  color: var(--color-white);
  padding: 2rem 0;
}
.popup .close {
  border: 1px solid #fff;
  padding: 1rem;
  margin-top: 1rem;
  width: 40%;
  text-align: center;
  font-size: 1.4rem;
  color: var(--color-white);
  cursor: pointer;
  border-radius: 25px;
  background: none;
}

/* RESPONSIVENESS FOR DEVICE */
@media screen and (max-width: 1024px) {
  .containers.contact_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact_text::after {
    display: none;
  }

  .contact_text {
    align-items: center;
    color: var(--color-b-primary);
    width: fit-content;
    font-weight: bolder;
    margin: auto;
    padding-bottom: 0;
    border-bottom: 2px solid var(--color-black);
  }
}

@media screen and (max-width: 600px) {
  .containers.contact_container {
    width: var(--container-width-ms);
  }

  .contact__btn {
    width: 100%;
  }
}
