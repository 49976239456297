#skills {
}

.experience_container {
  display: grid;
  background-color: #1f1f1f;
  grid-template-columns: 20% 80%;
  gap: 2rem;
  padding: 2rem;
}

.skills_heading {
  text-align: left;
}

.skills_heading h2 {
  font-size: 2rem;
  font-family: "Noto Serif JP", sans-serif;
  color: var(--color-white);
  font-weight: 600;
}

.skills_heading p {
  color: var(--color-b-primary);
  text-align: left;
  font-weight: 600;
}

.experience_content {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  padding: 1rem;
}

.experience_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 0.7rem;
}

.experience_details p {
  font-size: 0.9rem;
  color: var(--color-b-primary);
  font-weight: 600;
}

.experience_details img {
  width: 2rem;
}

/* Responsiveness */

@media screen and (max-width: 1024px) {
  .experience_container {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 600px) {
  .experience_content {
    gap: 1rem;
    align-items: center;
    padding: 0.8rem;
  }
  .experience_details p {
    font-size: 0.9rem;
    color: var(--color-b-primary);
  }
}
