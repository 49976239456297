footer {
  padding: 2rem 0;
}

.footer_social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.7rem;
  margin-bottom: 8rem;
  margin-top: 8rem;
  text-align: center;
}

.footer_social h2 {
  font-size: 2rem;
}

.footer_copyright {
  color: var(--color-white);
  font-size: 1rem;
  width: 100%;
  text-align: center;
}

/* RESPONSIVENESS FOR DEVICE */
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
}
