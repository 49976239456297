.portfolio_container {
  display: grid;
  gap: 4rem;
  padding-bottom: 10rem;
}

.portfolio_head h2 {
  font-size: 3rem;
  font-family: "Noto Serif JP", sans-serif;
}

.portfolio_head p {
  font-size: 1rem;
  color: var(--color-b-primary);
  font-weight: 600;
}

.portfolio_item {
  background: transparent;
  transition: var(--transition);
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 2rem;
  /* background-color: red; */
}

.portfolio_item_image {
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1f1f1f;
  padding: 2rem;
  /* width: 100%; */
  transition: transform 0.2s ease;
}

.portfolio_image {
  border-radius: 10px;
  filter: drop-shadow(1px 5px 4px grey);
}

.portfolio_image:hover {
  transform: scale(1.1);
  transition: ease-in-out;
}

.portfolio_item-cta {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.portfolio_item-cta h3 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  align-items: center;
}

.portfolio_item-cta h3 a {
  color: var(--color-white);
  display: block;
  text-decoration: none;
  font-family: "Noto Serif JP", sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

.portfolio_item-cta p {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--color-b-primary);
  font-weight: 500;
}

.stack-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.portfolio_item-cta .stacks {
  color: var(--color-b-primary);
  font-size: 0.8rem;
}

.portfolio_item-cta_link {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 2px solid var(--color-white);
  padding-bottom: 10px;
  color: #d3e97a;
}

.portfolio_item-cta_link a {
  color: #d3e97a;
  font-weight: bold;
  word-spacing: 2px;
}

/* RESPONSIVENESS FOR DEVICE */
@media screen and (max-width: 1024px) {
  .portfolio_item {
    grid-template-columns: 100%;
  }

  .portfolio_text {
    align-items: center;
    color: var(--color-b-primary);
    width: fit-content;
    font-weight: bolder;
    margin: auto;
    padding-bottom: 0;
    border-bottom: 2px solid var(--color-black);
  }
}

@media screen and (max-width: 768px) {
  .portfolio_head {
    padding-left: 1rem;
  }
  .portfolio_head h2 {
    font-size: 3rem;
    font-family: "Noto Serif JP", sans-serif;
  }

  .portfolio_head p {
    font-size: 0.8rem;
    text-align: left;
  }

  .portfolio_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .portfolio_item-cta p {
    text-align: left;
  }

  .portfolio_item_image {
    margin: 0rem 2rem;
    padding: 1rem;
  }

  .portfolio_item-cta h3 a {
    font-size: 1.5rem;
  }
}
