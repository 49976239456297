div > a > img {
  width: 60px;
}

.header_fixed_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.8rem 0 1.8rem;
  height: 70px;
  font-size: 0.8rem;
  position: fixed;
  width: 100%;
  top: 0px;
  /* left: 50px; */
  z-index: 99;
}

.nav_background {
  background-color: var(--color-black);
  /* filter: drop-shadow(12px 8px 4px grey); */
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 1px solid var(--color-white);
}

.header_link {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #222;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  width: 50%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: -120%;
  /* align-items: center; */
  padding: 6rem;
  margin: 0 0px 0 380px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  /* overflow-x: scroll; */
}

.header_link.active {
  right: 0%;
}

.active {
  color: #b9b9b9;
}

ul > li > a {
  color: var(--color-white);
  padding: 0.9rem 1.5rem;
  white-space: nowrap;
  font-weight: 500;
  font-size: 0.8rem;
  cursor: pointer;
}

ul > li {
  padding-bottom: 1.5rem;
}

ul > li > a:hover {
  font-weight: bolder;
}

.list_icon {
  padding: 14px 0px 0 0;
  font-size: 1.8rem;
}

.menu {
  font-size: 2rem;
  color: black;
  background-color: #fff;
  width: 3.5rem;
  text-align: center;
  transition: ease;
}
.header_fixed_text {
  text-align: center;
  margin-top: 1rem;
  border-top: 0.2rem solid #fff;
}

.header_fixed_text h3 {
  font-size: 2rem;
  color: #fff;
}

.header_fixed_text p {
  font-size: 1rem;
}

.header_fixed_social {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* ----Media Queries (Medium Device) -----*/
@media (min-width: 320px) and (max-width: 1024px) {
  .header_fixed_container {
    z-index: 99;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;

    padding: 0 2rem;
  }

  .header_link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #222;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: -120%;
    align-items: center;
    padding: 50px 0 10px 0;
    margin: 0 0px 0 380px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .header_link.active {
    right: 0%;
  }
}
