/* src/components/Availability.css */
.availability {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--color-primary);
}

.status-dot {
  height: 10px;
  width: 10px;
  background-color: #d3e97a;
  border-radius: 50%;
  margin-right: 8px;
  animation: _pulse_p9ec1_38 1.25s infinite linear;
}

@keyframes _pulse_p9ec1_38 {
  0% {
    box-shadow: 0 0 #f4821e29;
  }
  20% {
    box-shadow: 0 0 0 2px #f4821e42;
  }
  40% {
    box-shadow: 0 0 0 4px #f4821e8f;
  }
  60% {
    box-shadow: 0 0 0 6px #f4821e42;
  }
  100% {
    box-shadow: 0 0 0 7px #f4821e00;
  }
}
