.about_container {
  display: grid;
  grid-template-columns: 25% 70%;
  gap: 4%;
  margin-top: 0;
  background-color: #1f1f1f;
  padding: 2rem;
  /* height: 100vh; */
}

/* .back .icon {
  padding: 2rem;
} */
.about_me h4 {
  font-size: 3rem;
  text-align: center;
  font-family: "Noto Serif JP", sans-serif;
  color: var(--color-white);
}

.about_me {
  text-align: center;
  font-family: "Noto Serif JP", sans-serif;
  color: var(--color-white);
}

.about_cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about_card {
  border: 1px solid var(--color-black);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  background: transparent;
}

.about_content p {
  text-align: left;
  line-height: 1.5rem;
  font-weight: 600;
  word-spacing: 2px;
  color: var(--color-b-primary);
  padding-bottom: 2rem;
}

.about_content .more_about {
  font-weight: bolder;
  color: #d3e97a;
}

.about_social {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 3rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* ----------Medium Device design----- */

@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 100%;
    gap: 1rem;
  }

  .about_me h4 {
    text-align: center;
    font-size: 2rem;
  }

  .about_me {
    width: 50%;
    margin: 0 auto 0rem;
  }

  .about_content p {
    /* margin: 1rem 0 1.5rem; */
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .about_container {
    display: grid;
    grid-template-columns: 100%;
  }

  .about_content {
    width: 100%;
  }

  .about_me h4 {
    font-size: 1.8rem;
    text-align: center;
  }

  .about_content p {
    text-align: left;
    line-height: 1.5rem;
    word-spacing: 2px;
    color: var(--color-b-primary);
    font-weight: 600;
    padding-bottom: 1.5rem;
  }

  .about_cards {
    grid-template-columns: 1fr;
    /* for more than one card in the container */
    /* grid-template-columns:  1fr 1fr; */
    gap: 1rem;
  }

  /* .about_content {
    text-align: center;
  } */
}
