.scroll-up-button {
  position: fixed;
  bottom: 40px;
  right: 35px;
  background-color: #000;
  color: #d3e97a;
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s,
    box-shadow 0.3s;
  animation: bounce 0.5s infinite linear;
  z-index: 1000;
}

.scroll-up-button:hover {
  background-color: #555;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  animation: bounce 0.5s infinite linear;
}

.scroll-up-button:active {
  transform: scale(0.95);
}

.scroll-up-button.show {
  opacity: 1;
  transform: translateY(0);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* src/components/Cursor.css */
._cursorWrapper_p627f_1 {
  z-index: 1000;
  border-radius: 50%;
  pointer-events: none;
  overflow: hidden;
  transform: translateZ(0);
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out,
    transform 0.45s cubic-bezier(0, -0.1, 0, 0.99);
}

._animate_p627f_14 ._default_p627f_19 {
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.7); /* Adjust transparency */
  mix-blend-mode: difference;
  border-radius: 50%;
}

._animate_p627f_14 {
  transition: all 0.3s cubic-bezier(0.59, -0.09, 0, 1);
  overflow: hidden;
}

/* ----Media Queries (Small Device) -----*/
@media screen and (max-width: 600px) {
  .scroll-up-button {
    position: fixed;
    bottom: 20px;
    right: 15px;
  }
  ._cursorWrapper_p627f_1 {
    display: none;
  }
}
