.container.testimonials_container{
    width: 40%;
    padding-bottom: 4rem;
}

.client_avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    margin: 0 auto 1rem;
    border-radius: 50%;
    border: 0.4rem solid var(--color--primary-variant);
}

.testimonial{
    background: var(--color--bg-variant);
    text-align: center;
    padding: 2rem;  
    border-radius: 2rem;
    user-select: none;
}

.client_review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
    background-color: var(--color-primary);
}

/* RESPONSIVENESS FOR DEVICE */
@media screen and (max-width: 1024px) {
    .container.testimonials_container{
        width: 60%;
    }    
}

@media screen and (max-width: 600px) {
    .container.testimonials_container{
        width: var(--container-width-ms);
    }  

    .client_review{
        width: var(--container-width-ms);
    }
}

