#service {
}

.services_container {
  display: grid;
  grid-template-columns: 1fr;
}

.service_text {
  margin-left: 10rem;
  margin-bottom: 0;
  display: flex;
  padding-bottom: 0;
}

.service {
  /* border-radius: 0 0 2rem 2rem; */
  /* border: 1px solid var(--color-b-primary); */
  height: fit-content;
  transform: var(--transition);
}

.service_head {
  border: 1px solid var(--color-b-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.service_head h3 {
  color: var(--color-b-primary);
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.service_list {
  padding: 2rem;
}

.service_list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service_list-icon {
  color: var(--color-b-primary);
  margin-top: 2px;
  font-size: 1.2rem;
}

.service_list p {
  font-size: 1rem;
  font-weight: 500;
}

/* RESPONSIVENESS FOR DEVICE */
@media screen and (max-width: 1024px) {
  .services_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .service {
    height: auto;
  }

  .service_text {
    align-items: center;
    color: var(--color-b-primary);
    width: fit-content;
    font-weight: bolder;
    margin: auto;
    padding-bottom: 0;
    border-bottom: 2px solid var(--color-black);
  }
}

@media screen and (max-width: 600px) {
  .services_container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
