@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
}

:root {
  --container-width-lg: 100%;
  --container-width-md: 85%;
  --container-width-ms: 90%;
  --transition: all 400ms ease;
  --color-light: #c7c7c7;
  --color-white: #ffffff;
  --color-whitesmoke: #fafafa;
  --color-purple: #694ac4;
  --color-black: black;
  --color-b-primary: #b9b9b9;
  --color-dark: #000000;
}

html {
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar{
    display: none;
} */

body {
  background-color: var(--color-dark);
  font-family: Comic Neue, sans-serif !important;
  /* font-family: "Noto Serif JP", sans-serif; */
  /* font-family: "Source Sans Pro", sans-serif; */
  color: var(--color-white);
  line-height: 1.5;
}

/* -------General styling----- */

.containers {
  width: var(--container-width-ms);
  margin: 2.5rem auto 2rem;
}

section {
  padding-top: 4rem;
  padding-bottom: 2rem;
  overflow: hidden;
}

/* section > h2 {
  font-size: 2rem;
  letter-spacing: 3px;
  color: var(--white);
  text-align: left;
  font-weight: 800;
} */

/* section > h5 {
  font-size: 0.9rem;
  text-align: left;
  color: #b9b9b9;
} */

a {
  /* color: var(--color-black); */
  color: var(--color-light);
  transition: var(--transition);
}

/* .btn {
 
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  cursor: pointer;
  font-weight: bold;
  background: #d3e97a;
  color: var(--color-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
} */

.btn {
  /* display: inline-block; */
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  color: var(--color-dark);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  border: none;
  border-radius: 10px;
  z-index: 0;
  background: #d3e97a;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn:hover {
  color: #d3e97a;
  background-color: transparent;
  font-weight: bold;
  border: 2px solid #d3e97a;
}
.btn:hover:after {
  height: 100%;
}
.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 8px;
}

/* .btn:hover {
  background-color: transparent;
  color: #d3e97a;
  border: 2px solid #d3e97a;
  font-weight: bold;
} */

.btn-primary {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  border: 2px solid #d3e97a;
  border-radius: 10px;
  z-index: 0;
  background: transparent;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-primary:hover {
  background: #d3e97a;
  color: var(--color-white);
}

img {
  display: block;
  width: 100%;
}

/* ----------Medium Device design----- */

@media screen and (max-width: 1024px) {
  .containers {
    width: var(--container-width-md);
  }

  .containers {
    width: var(--container-width-ms);
    margin: 0 auto 2rem;
  }

  section {
    padding-top: 4rem;
  }

  section {
    margin-top: 0rem;
  }

  section > h2 {
    font-size: 1.5rem;
    letter-spacing: 3px;

    color: var(--color-white);
    text-align: left;
    font-weight: 800;
  }

  section > h5 {
    font-size: 0.7rem;
    /* margin: 0 2rem 3rem; */
    text-align: left;
    color: #b9b9b9;
  }
}

@media screen and (max-width: 600px) {
  .containers {
    width: var(--container-width-lg);
  }
}

/* @media (min-width: 320px) and (max-width: 1920px) {
  header,
  #about,
  #skills,
  #portfolio,
  #service {
    min-height: 100vh;
  }
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
