header {
  padding-top: 4rem;
  padding-bottom: 3rem;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header_box {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 25rem;
  height: 25rem;
}

.header_box .header_box_left {
  font-size: 2.5rem;
  color: var(--color-b-primary);
  font-family: "Noto Serif JP", sans-serif;
  margin-bottom: 2rem;
}

.header_box .header_box_right {
  font-size: 2.5rem;
  color: var(--color-b-primary);
  font-family: "Noto Serif JP", sans-serif;
  margin-top: 2rem;
}

.header_box .vertical-line {
  margin-top: 3rem;
  width: 2px;
  background-color: var(--color-b-primary);
  margin: auto;
  height: 0; /* Start with height 0 */
  animation: lineup 10s forwards ease-in-out;
}

@keyframes lineup {
  0% {
    height: 0px;
  }
  100% {
    height: 20rem;
  }
}

.header_box .vertical-line .animate-line {
  animation: lineup 5s forwards;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}

.header_container h1 {
  font-size: 4rem;
  text-align: center;
  font-weight: bolder;
  font-family: "Noto Serif JP", sans-serif;
  color: var(--color-white);
}

.header_container p {
  color: var(--color-light);
  font-size: 1.3rem;
  text-align: center;
  width: 70%;
  font-weight: 600;
}

p strong {
  color: var(--color-white);
  font-weight: 600;
  font-size: 1.3rem;
}

/* CTA Button */

.cta {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/*----- Social styling ----*/
.header_socials_left {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  /* position: absolute; */
  position: fixed;
  top: 15rem;
  left: 2rem;
  bottom: 6rem;
  font-size: 1.3rem;
  border: 10px;
  height: 100%;
  /* background: var(--color-white); */
  width: 58px;
  padding: 18px;
  color: #b9b9b9;
}

/*----- Social styling ----*/
.header_socials_left::after {
  content: "";
  background: #fff;
  width: 2px;
  height: 50rem;
}

.header_socials_right {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  position: fixed;
  top: 23rem;
  right: 2rem;
  bottom: 6rem;
  font-size: 1.3rem;
  border: 10px;
  height: 100%;
  width: 58px;
  padding: 18px;
}

.header_socials_right a {
  transform: rotate(-90deg);
  color: #b9b9b9;
  width: 20rem;
  margin-bottom: 3rem;
}

.header_socials_right a:hover {
  color: #d3e97a;
}

.header_socials_left a:hover {
  color: #d3e97a;
}

/*----- Right Social styling  ----*/
.header_socials_right::after {
  content: "";
  background: #fff;
  width: 2px;
  height: 50rem;
}
/* myself */

.me {
  background: linear-gradient(#1f1f1f, transparent);
  /* background-color: #1f1f1f; */
  width: 22rem;
  height: 18rem;
  justify-content: center;
  margin-top: 2rem;
  /* border-radius: 12rem 12rem 0 0; */
  overflow: hidden;
  padding: 1rem 1rem 1rem 1rem;
}

.me img {
  /* border-radius: 12rem 12rem 0 0; */
}

/* scroll down sytling */

/* ----Media Queries (Medium Device) -----*/
@media screen and (max-width: 1024px) {
  .header_socials_left {
    display: none;
  }
  .header_socials_right {
    display: none;
  }

  .header_container h5 {
    font-size: 1.3rem;
    color: var(--color-b-primary);
    font-weight: bold;
  }
  .dotgrid_dotGrid__fIloK {
    width: 100%;
  }
  .header_container p {
    width: 100%;
  }
}

/* ----Media Queries (Small Device) -----*/

@media screen and (max-width: 600px) {
  .header_box {
    width: 100%;
  }
  /* .header_box .header_box_left {
    font-size: 1.5rem;
  } */
  /* .header_box .header_box_right {
    font-size: 1.5rem;
  } */
  h1 {
    margin-top: 1rem;
  }

  .me {
    /* background: linear-gradient(#1f1f1f, transparent); */

    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .header_socials_left {
    display: none;
  }

  .header_socials_right {
    display: none;
  }

  .header_container h1 {
    font-size: 2rem;
    font-weight: bolder;
  }

  .header_container p {
    font-size: 1.2rem;
    padding: auto;
    text-align: center;
    padding: 0 1rem;
  }

  p strong {
    font-size: 1.1rem;
  }

  .dotgrid_dotGrid__fIloK {
    width: 100%;
  }
}
